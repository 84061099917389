import createAuth0Client from '@auth0/auth0-spa-js';

import { ApiConfig } from './src/shared/models/api-config.model';

fetch('/api/config').then(async response => {
  const data = await response.json();
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} ${response.url}: ${data.message}`);
  }
  return data;
}).then(async apiConfig => {
  (window as any).flywheelApiConfig = apiConfig;
  const auth0Config = apiConfig.auth.auth0;
  const auth0Enabled = isAuth0Enabled(apiConfig);
  const token = localStorage.getItem('satellizer_token');
  if (token && !location.hash) {
    // skip the login page if we have a token
    location.hash = '#/projects';
  }
  if (!auth0Enabled || token || location.search.includes('code=')) {
    // bootstrap the app
    return loadMainApplication();
  }
  // log in with auth0
  const auth0 = await createAuth0Client({
    domain: auth0Config.domain,
    client_id: auth0Config.client_id,
    audience: auth0Config.audience,
  });
  auth0.loginWithRedirect({
    redirect_uri: window.location.href,
  });
}).catch(error => {
  (window as any).flywheelApiConfig = {
    error: error,
    auth: {},
    features: {},
    site: {},
  };
  // let the main app handle any error
  loadMainApplication();
});

function loadMainApplication() {
  import(/* webpackChunkName: "index" */'./index');
}

function isAuth0Enabled(apiConfig: ApiConfig): boolean {
  if (!apiConfig.auth.auth0) {
    return false;
  }
  if (location.search.includes('auth0=false')) {
    return false;
  }
  if (location.search.includes('auth0=true')) {
    return true;
  }
  if (typeof apiConfig.features.auth0 === 'boolean') {
    return apiConfig.features.auth0;
  }
  return true;
}
